<template>
  <div class="pa-4">
    <v-tabs fixed-tabs>
      <v-tab v-for="tab in tabs" :key="tab.label" :to="tab.path">
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
    <ConfirmDialog ref="confirmDiaglog" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmDialog from '@/components/UI/ConfirmDiaglog';

export default {
  components: {
    ConfirmDialog,
  },
  computed: {
    ...mapState('auth', ['role']),
    tabs() {
      const tabs = {
        superAdmin: [
          { label: 'Organisations', path: '/organisations' },
          { label: 'Utilisateurs', path: '/users' },
        ],
        orgAdmin: [
          { label: 'Utilisateurs', path: '/users' },
          { label: 'Dispositifs', path: '/devices' },
        ],
        orgUser: [
          { label: 'Dispositifs', path: '/devices' },
        ],
      };

      return tabs[this.role] || tabs.superAdmin;
    },
  },
  mounted() {
    this.$root.$confirmDiaglog = this.$refs.confirmDiaglog.open;
  },
  created() {
    if ((this.role === 'superAdmin' || this.role === 'eyeStaff')
      && this.$route.path === '/') {
      this.$router.replace('/organisations');
    }
  },
};
</script>
